import gql from "graphql-tag";

export const GET_CLIENT_TEMPLATES = gql`
  query GetClientTemplates($clientID: uuid) {
    documentTemplateUse(where: { clientID: { _eq: $clientID }, clientLocationID: { _is_null: true } }) {
      id
      status
      clientID
      clientLocationID
      template {
        id
        title
        version
        updatedBy
        updated_at
        originalID
        isDDI
        status
        signedDocuments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_LOCATION_TEMPLATES = gql`
  query GetLocationTemplates($locationID: uuid) {
    documentTemplateUse(where: { clientLocationID: { _eq: $locationID }, partnerID: { _is_null: true } }) {
      id
      status
      template {
        id
        title
        version
        updatedBy
        updated_at
        originalID
        isDDI
        status
        signedDocuments_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
export const LATEST_TEMPLATE_VERSION_BY_LOCATION = gql`
  query LatestTemplateByLocation($clientID: uuid!, $locationID: uuid) {
    documentTemplateUse(
      where: {
        _or: [
          { _and: [{ clientID: { _eq: $clientID }, clientLocationID: { _eq: $locationID }, template: { status: { _eq: "published" } } }] }
          { _and: [{ clientID: { _eq: $clientID } }, { clientLocationID: { _is_null: true } }, { status: { _neq: "original" } }, { template: { status: { _eq: "published" } } }] }
        ]
      }
    ) {
      id
      status
      clientID
      clientLocationID
      template {
        id
        title
        version
        updatedBy
        updated_at
        originalID
        isDDI
        status
      }
    }
  }
`;

export const LATEST_TEMPLATE_VERSION_BY_CLIENT = gql`
  query GetLatestTemplateVersionByClient($clientID: uuid) {
    documentTemplateUse(
      where: {
        _or: [
          { _and: [{ clientID: { _eq: $clientID }, status: { _eq: "original" }, template: { status: { _neq: "disabled" } } }] }
          { _and: [{ clientID: { _eq: $clientID }, clientLocationID: { _is_null: true }, status: { _eq: "inherited" }, template: { status: { _neq: "disabled" }, isDDI: { _eq: true } } }] }
        ]
      }
    ) {
      id
      status
      clientID
      clientLocationID
      template {
        id
        title
        version
        updatedBy
        updated_at
        originalID
        isDDI
        status
      }
    }
  }
`;

// *******************************************************************
export const GET_LOCATION_DETAILS = gql`
  query GetLocationDetails($locationID: uuid) {
    clientLocations(where: { id: { _eq: $locationID } }) {
      id
      name
      phone
      locationConfiguration
      client {
        id
        name
      }
      address {
        id
        address1
        address2
        city
        state
      }
      teamSpecialist {
        employee {
          id
          firstName
          lastName
          role
        }
      }
      partners_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_CLIENT_LOCATION_BRANDING = gql`
  query GetClientLocationBranding($clientLocationID: uuid) {
    clientLocations(where: { id: { _eq: $clientLocationID } }) {
      id
      color
      logo
    }
  }
`;

export const FIND_TEMPLATES_WITH_LOCATIONS = gql`
  query findTemplatesWithLocations($clientID: uuid!) {
    financeTemplates(where: { _and: [{ clientID: { _eq: $clientID } }, { locationID: { _is_null: false } }] }) {
      id
      clientLocation {
        name
      }
    }
  }
`;

export const GET_CLIENT_FINANCE_TEMPLATE_ID = gql`
  query getClientFinanceTemplateID($clientID: uuid!) {
    financeTemplates(where: { _and: [{ clientID: { _eq: $clientID } }, { locationID: { _is_null: true } }, { locationPartnerID: { _is_null: true } }] }) {
      id
    }
  }
`;

export const GET_FINANCE_TEMPLATE_BY_ID = gql`
  query getFinanceTemplateById($templateID: uuid!) {
    financeTemplates_by_pk(id: $templateID) {
      id
      templateType
      locationID
      client {
        id
        name
      }
      payPeriod {
        id
        payCycle
        partnerSettlementDay
        startDay
        paymentProcessor
      }
      setttlementItems {
        id
        name
        description
        transactionType
        category
        settlementItemType
        taxSetting
        additionalData
        billTo
        status
        overriddenSettlementItemID
      }
    }
  }
`;

export const GET_FINANCE_TEMPLATE = gql`
  subscription getLocationFinanceTemplate($clientID: uuid!, $locationID: uuid!) {
    financeTemplates(
      where: { _or: [{ _and: [{ clientID: { _eq: $clientID } }, { locationID: { _is_null: true } }] }, { _and: [{ clientID: { _eq: $clientID } }, { locationID: { _eq: $locationID } }] }] }
    ) {
      id
      templateType
      locationID
      client {
        id
        name
      }
      payPeriod {
        id
        payCycle
        partnerSettlementDay
        startDay
        paymentProcessor
      }
      setttlementItems {
        id
        name
        description
        transactionType
        category
        settlementItemType
        taxSetting
        additionalData
        billTo
        status
        overriddenSettlementItemID
        overriddenModifier {
          id
          name
          description
          transactionType
          category
          settlementItemType
          taxSetting
          additionalData
          billTo
        }
      }
    }
  }
`;

export const GET_LOCATION_NAME = gql`
  query getLocationName($id: uuid!) {
    location: clientLocations_by_pk(id: $id) {
      id
      name
    }
  }
`;
